// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/lib/locale/pt'
import es from 'vuetify/lib/locale/es'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pt, es },
    current: 'pt'
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#698DF2',
        secondary: '#38C7C2',
        accent: '#FF4766',
        error: '#F56A47',
        warning: '#ffc107',
        info: '#607d8b',
        success: '#43D0D0',
      },
    },
  },
})
