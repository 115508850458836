// Archived
const dict = {
  default: {
    'dashboard': 'Dashboard',
    'campaign': 'Campanha',
    'campaigns': 'Campanhas',
    'ad': 'Anúncio',
    'ads': 'Anúncios',
    'buzzers': 'Buzzers',
    'drivers': 'Motoristas',
  },
  '15': {
    'dashboard': 'Painel',
    'campaign': 'Comunicação',
    'campaigns': 'Comunicações',
    'ad': 'Comunicado',
    'ads': 'Comunicados',
  }
}

export default dict